<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <div class="titleAction">
            <h1 class="systemTitle">Accounts</h1>
            <router-link to="/business/addAccount"
              ><v-btn large>
                <v-icon class="mr-2"> mdi-plus </v-icon> Add User</v-btn
              ></router-link
            >
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="greenBack">
                  <th class="text-left">Fullname</th>
                  <th class="text-left">Email</th>
                  <th class="text-left">Role</th>
                  <th class="text-left">Account Status</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr
                  v-for="(data, index) in users"
                  :key="index + 'product'"
                  class="rowBox"
                >
                  <td width="120" v-if="false">
                    <img
                      v-if="data.images.length > 0"
                      :src="data.images[0].image"
                      alt=""
                      width="100"
                    />
                  </td>
                  <td>{{ data.fullname }}</td>
                  <td>
                    {{data.email}}
                  </td>
                  <td class="text-capitalize">{{ data.role }}</td>
                  <td>
                    <v-switch v-model="data.status" @change="changeStatus(data._id)"></v-switch>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="loading">
                <tr>
                  <td>
                    Loading
                    <v-progress-circular
                      indeterminate
                      size="20"
                      width="2"
                      class="ml-2"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="!loading">
                <tr>
                  <td colspan="6">
                    <ul class="pagination">
                      <li v-if="pagination.previous">
                        <v-icon @click="prev(pagination.previous)">
                          mdi-chevron-left
                        </v-icon>
                      </li>
                      <li>
                        <span v-if="pagination.previous"
                          >{{
                            pagination.previous + 1 > 1
                              ? pagination.previous + 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        <span v-if="pagination.next"
                          >{{
                            pagination.next - 1 > 1
                              ? pagination.next - 1 * limit
                              : 1
                          }}
                          -</span
                        >
                        {{ pagination.total }} Items
                      </li>
                      <li v-if="pagination.next">
                        <v-icon @click="next(pagination.next)">
                          mdi-chevron-right
                        </v-icon>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AccountList",
  data: () => ({
    users: [],
    loading: true,
    loadingDelete: false,
    id: undefined,
    pagination: undefined,
    limit: 10,
    page: 1,
  }),
  computed: {
    ...mapGetters(["allUsers"]),
  },
  methods: {
    ...mapActions(["getUsers","putUserStatus"]),
    async changeStatus(id){
      await this.putUserStatus({id:id});
    },
    async removeProduct(id) {
      this.loadingDelete = true;
      this.id = id;
      await this.deleteProduct(id);
      this.loadingDelete = false;
      this.users = this.allUsers.results;
      this.pagination = this.allUsers.pagination;
    },
    async next(data) {
      this.loading = true;
      await this.getUsers({ limit: this.limit, page: data });
      this.users = this.allUsers.results;
      this.pagination = this.allUsers.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getUsers({ limit: this.limit, page: data });
      this.users = this.allUsers.results;
      this.pagination = this.allUsers.pagination;
      this.loading = false;
    },
  },
  async created() {
    await this.getUsers({ limit: this.limit, page: this.page });
    this.users = this.allUsers.results;
    this.pagination = this.allUsers.pagination;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.rowBox {
  td {
    padding: 10px !important;
  }
}
</style>